import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyCarousel = lazy(
  () => import("./carousel-CHvKkL-e.js").then((module) => ({
    default: module.Carousel
  }))
);
function Carousel({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyCarousel, { ...props });
}
const useCarouselContent = () => {
  return useMemo(
    () => ({
      carousel: {
        component: Carousel
      }
    }),
    []
  );
};
export {
  LazyCarousel as Carousel,
  useCarouselContent
};
